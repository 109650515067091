<template>
  <div>
    <dashboard-page-title
      :showSearch="false"
      :showFilter="false"
      :showMainActions="false"
      @addEvent="$router.push({ name: 'addMerchant' })"
    >
      الإعدادات
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        v-if="Object.keys(userData).length"
      >
        <b-row>
          <b-col md="10">
            <div>
              <b-card class="iq-border-radius-10 mb-4">
                <template v-slot:header>
                  <h5 class="text-primary">البيانات الشخصية</h5>
                </template>
                <b-row>
                  <b-col lg="3">
                    <img
                      :src="userData.personal_picture"
                      class="img-fluid rounded-circle settings-user-profile"
                    />
                  </b-col>
                  <b-col lg="9">
                    <b-row>
                      <b-col xl="6">
                        <input-form
                          class="mb-3 joining-label"
                          v-model="userData.full_name"
                          validate="required"
                          name="name"
                          placeholder="الاسم بالكامل"
                          label="الاسم بالكامل"
                        />
                      </b-col>
                      <b-col xl="6">
                        <country-code
                          :defaultCountry="
                            userData.country_code === '002' ? 'eg' : 'sa'
                          "
                          validate="required|numeric"
                          placeholder="رقم الهاتف"
                          label="رقم الهاتف"
                          name="phone"
                          v-model="userData.primary_phone_number"
                          id="phone-code-primary_phone_number"
                          @onSelect="onSelect"
                        />
                      </b-col>
                      <b-col xl="6">
                        <input-form
                          class="mb-3 joining-label"
                          v-model="userData.email"
                          :validate="'required|email'"
                          name="id"
                          placeholder="Mahmoudsayed@gmail.com"
                          label="البريد الالكترونى"
                        />
                      </b-col>
                      <b-col xl="6">
                        <country-code
                          :defaultCountry="
                            userData.country_code === '002' ? 'eg' : 'sa'
                          "
                          :validate="'numeric'"
                          placeholder="رقم هاتف اخر"
                          label="رقم هاتف اخر"
                          name="phone2"
                          v-model="userData.secondary_phone_number"
                          id="phone-code-secondary_phone_number"
                          @onSelect2="onSelect2"
                        />
                      </b-col>
                      <b-col md="12">
                        <input-form
                          class="mb-3 joining-label"
                          v-model="userData.clinic_address"
                          :validate="'required'"
                          name="address"
                          placeholder="ادخل العنوان"
                          label="عنوان العيادة"
                        />
                      </b-col>
                      <b-col md="12">
                        <input-form
                          class="mb-3 joining-label"
                          v-model="ibanInput"
                          :validate="{ regex: /^['SA']{2}[0-9]{22}$/ }"
                          name="IBAN"
                          placeholder="ادخل رقم ال IBAN"
                          label="رقم IBAN"
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>

              <b-card class="iq-border-radius-10 mb-4">
                <template v-slot:header>
                  <h5 class="text-primary">
                    قم بتحديد الخدمات التى تقوم بتقديمها
                  </h5>
                </template>
                <div class="dashboard-custom-checkbox">
                  <b-form-checkbox-group
                    v-model="doctorServiceSelected"
                    :options="doctorServiceOptions"
                    class="dashboard-custom-checkbox-inner d-flex align-items-center flex-wrap"
                    value-field="item"
                    text-field="name"
                  ></b-form-checkbox-group>
                </div>
              </b-card>

              <b-card class="iq-border-radius-10 mb-4">
                <template v-slot:header>
                  <div class="d-flex align-items-center flex-wrap gap_2">
                    <h5 class="text-primary">طريقة الكشف</h5>
                    <div
                      class="d-flex align-items-center justify-content-between flex-wrap gap_1"
                    >
                      <b-form-radio-group
                        id="etectionMethod"
                        v-model="selectedDetectionMethod"
                        :options="optionsDetectionMethod"
                        aria-describedby="ariaDescribedby"
                        name="detectionMethod"
                        class="detection-custom-radio d-flex align-items-center"
                      ></b-form-radio-group>
                      <div
                        class="d-flex align-items-center gap_2 setting-box-inp-detection-time"
                        v-if="selectedDetectionMethod === 'appointment'"
                      >
                        <h6
                          class="setting-box-title setting-box-title-detection-time font-size-16"
                        >
                          مدة الكشف
                        </h6>
                        <div class="d-flex align-items-center gap_2">
                          <div class="d-flex align-items-center gap_1">
                            <input-form
                              class="text-center m-0 matone-value"
                              placeholder="1"
                              v-model="examination_duration.hours"
                              validate="required|numeric"
                              name="hours"
                            />
                            <span class="small-text">ساعة</span>
                          </div>
                          <div class="d-flex align-items-center gap_1">
                            <input-form
                              class="text-center m-0 matone-value"
                              placeholder="30"
                              v-model="examination_duration.minutes"
                              validate="required|numeric|min_value: 5"
                              name="minutes"
                            />
                            <span class="small-text">دقيقة</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>

                <div class="">
                  <h5 class="text-primary">مواعيد العمل</h5>
                  <div class="p-4">
                    <div
                      class="d-flex align-items-center gap_1 time-of-work-item flex-wrap"
                      v-for="(timeOfWork, index) in work_times"
                      :key="index"
                    >
                      <div
                        class="time-of-work-item-controls d-flex align-items-center gap_1"
                      >
                        <span
                          class="time-of-work-item-controls-label font-size-20"
                          >{{ timeOfWork.day }}</span
                        >

                        <b-form-checkbox
                          :id="`time-of-work-item-controls-checkbox-${index}`"
                          v-model="timeOfWork.is_active"
                          :name="`time-of-work-item-controls-checkbox-${index}`"
                          class="d-flex align-items-center dashboard-custom-checkbox time-of-work-item-controls-checkbox"
                          :value="false"
                          :unchecked-value="true"
                          ariaDescribedby="item-checkbox-day"
                        >
                          مغلق
                        </b-form-checkbox>
                      </div>
                      <div
                        v-if="timeOfWork.is_active"
                        class="d-flex flex-column gap_1"
                      >
                        <div
                          class="d-flex align-items-center gap_4 flex-wrap"
                          v-for="(timeItem, idx) in timeOfWork.working_periods"
                          :key="idx"
                        >
                          <div class="d-flex align-items-center gap_1">
                            <h6
                              class="setting-box-title time-of-work-item-label font-size-20"
                            >
                              من
                            </h6>
                            <flat-pickr
                              v-model="timeItem.from"
                              :config="config"
                              class="form-control time-of-work-item-controls-date"
                              placeholder="Select time"
                              name="time-of-work-item-controls-date"
                            />
                          </div>
                          <div class="d-flex align-items-center gap_1">
                            <h6
                              class="setting-box-title time-of-work-item-label font-size-20"
                            >
                              إلى
                            </h6>
                            <flat-pickr
                              v-model="timeItem.to"
                              :config="config"
                              class="form-control time-of-work-item-controls-date"
                              placeholder="Select time"
                              name="time-of-work-item-controls-date-to"
                            />
                          </div>
                          <b-button
                            v-if="idx === timeOfWork.working_periods.length - 1"
                            class="d-flex align-items-center d-flex align-items-center bg-transparent border-0 p-0 time-of-work-item-add-btn"
                            @click="addTimeOfWork(index)"
                          >
                            <i class="las la-clock icon"></i>
                            <span>اضافة فترة عمل اخرى</span>
                          </b-button>
                        </div>
                        <div
                          v-if="
                            selectedDetectionMethod ===
                            'first-come-first-served'
                          "
                        >
                          <h6 class="setting-box-title">
                            الحد الأقصى للحجوزات
                          </h6>
                          <input-form
                            class="text-center m-0 matone-value w-50"
                            :placeholder="'عدد الحجوزات'"
                            v-model="max_examinations_count[index]"
                            validate="required|numeric"
                            name="max_examinations_Count"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>

              <b-card class="iq-border-radius-10 mb-4">
                <template v-slot:header>
                  <h5 class="text-primary">الأسعار</h5>
                </template>
                <b-row class="" v-if="doctorServiceSelected.length">
                  <b-col
                    sm="12"
                    v-for="(
                      doctorServiceSelected, index
                    ) in doctorServiceSelected"
                    :key="index"
                  >
                    <div
                      class="setting-box-inner d-flex align-items-center gap_2"
                      v-if="doctorServiceSelected.allow_clinic_examine"
                    >
                      <h6 class="setting-box-title font-size-20">
                        كشف العيادة
                      </h6>
                      <div class="d-flex align-items-center gap_1">
                        <input-form
                          class="text-center m-0 matone-value"
                          placeholder=""
                          v-model="prices.clinic_examine"
                          validate="required|numeric"
                          name="clinic_examine"
                        />
                        <span class="small-text">ريال سعودى</span>
                      </div>
                    </div>
                    <div
                      class="setting-box-inner d-flex align-items-center gap_2"
                      v-if="doctorServiceSelected.allow_outdoor_examine"
                    >
                      <h6 class="setting-box-title font-size-20">
                        زيارة منزلية
                      </h6>
                      <div class="d-flex align-items-center gap_1">
                        <input-form
                          class="text-center m-0 matone-value"
                          placeholder=""
                          v-model="prices.outdoor_examine"
                          validate="required|numeric"
                          name="outdoor_examine"
                        />
                        <span class="small-text">ريال سعودى</span>
                      </div>
                    </div>
                    <div
                      class="setting-box-inner d-flex align-items-center gap_2"
                      v-if="doctorServiceSelected.allow_consulting"
                    >
                      <h6 class="setting-box-title font-size-20">استشارة</h6>
                      <div class="d-flex align-items-center gap_1">
                        <input-form
                          class="text-center m-0 matone-value"
                          placeholder=""
                          v-model="prices.consulting"
                          validate="required|numeric"
                          name="consulting"
                        />
                        <span class="small-text">ريال سعودى</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-else class="justify-content-center">
                  <b-spinner label="loading..."></b-spinner>
                </b-row>
              </b-card>
            </div>
            <div v-if="userData.status === 'pending'">
              <b-row>
                <b-col
                  md="4"
                  class="mb-4"
                  v-for="(itemFile, index) in requiredDocuments"
                  :key="index"
                >
                  <img-upload-box
                    :data="itemFile"
                    :index="index"
                    @uploadDocument="uploadDocument"
                    :src="itemFile.url"
                  />
                </b-col>
              </b-row>
            </div>

            <div v-else-if="userData.status === 'processing'">
              <b-alert variant="danger" show>
                حالة الطلب :- يتم معالجة المستندات.
              </b-alert>
              <b-row>
                <b-col
                  md="4"
                  class="mb-4"
                  v-for="(itemFile, index) in userData.uploaded_documents
                    .length > 0
                    ? userData.uploaded_documents
                    : requiredDocuments"
                  :key="index"
                >
                  <img-upload-box
                    :data="itemFile.doc || itemFile"
                    @uploadDocument="uploadDocument"
                    :src="itemFile.url"
                  >
                    <template v-slot:first>
                      <img
                        class="img-box d-flex align-items-center justify-content-center flex-column w-100 h-100"
                        :src="itemFile.url"
                        alt="this is image"
                      />
                    </template>
                  </img-upload-box>
                </b-col>
              </b-row>
            </div>

            <div class="d-flex justify-content-center">
              <b-button
                variant="primary"
                class="vete-save-btn m-auto"
                type="submit"
                v-if="!loadingButtonSubmit"
              >
                حفظ
              </b-button>
              <b-button variant="primary" class="vete-save-btn m-auto" v-else>
                <spinner-loading :text="'يتم التحميل'"></spinner-loading>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
      <b-row class="justify-content-center" v-else>
        <b-spinner label="loading..."></b-spinner>
      </b-row>
    </ValidationObserver>
  </div>
</template>

<script>
/*eslint-disable*/
import { core } from "@/config/pluginInit";
import doctorApi from "../services/settings";
import imgUploadBox from "../components/imgUploadBox";
export default {
  mounted() {
    core.index();
  },
  components: { imgUploadBox },
  props: {
    src: {
      type: String,
      default: "",
    },
    backgroundImage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loadingButtonSubmit: false,
      userData: {},
      userInfo: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo")).user
        : {},
      doctorServiceSelected: [],
      doctorServiceOptions: [
        { item: { allow_clinic_examine: true }, name: "كشف عيادة" },
        { item: { allow_outdoor_examine: true }, name: "زيارة منزلية" },
        { item: { allow_consulting: true }, name: "استشارة هاتفية" },
      ],
      prices: {
        clinic_examine: "",
        consulting: "",
        outdoor_examine: "",
      },
      selectedDetectionMethod: "",
      optionsDetectionMethod: [
        { text: "بأولوية الحضور", value: "first-come-first-served" },
        { text: "ميعاد محدد", value: "appointment" },
      ],
      examination_duration: {
        hours: "",
        minutes: "",
        seconds: "00",
      },
      work_times: [], // Initialize as an empty array
      max_examinations_count: [], // Initialize as an empty array
      config: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: false,
        defaultDate: "14:30",
      },
      requiredDocuments: null,
      uploadedDocuments: [],
      code: "",
      code2: "",
      ibanInput: "", // Separate variable for IBAN
    };
  },
  methods: {
    onSelect(data) {
      this.code = data;
    },
    onSelect2(data) {
      this.code2 = data;
    },
    addTimeOfWork(idx) {
      this.work_times[idx].working_periods.push({ from: "", to: "" });
      this.max_examinations_count.splice(idx + 1, 0, "");
    },
    getUserInfo() {
      doctorApi.getDoctorInfo().then((response) => {
        this.userData = response.data;
        this.ibanInput = this.userData.IBAN; // Initialize ibanInput
        console.log("getRequiredDocuments userData info is ", this.userData);

        // Update work_times with max_appointments from backend
        this.work_times = response.data.work_times.map((item) => ({
          ...item, // Copy existing properties
          max_appointments: item.max_appointments || "", // Set max_appointments
        }));
      });
    },
    getRequiredDocuments() {
      doctorApi.getRequiredDocuments().then((response) => {
        this.requiredDocuments = response.data.data;
        console.log("getRequiredDocuments D info is ", this.requiredDocuments);
      });
    },
    uploadDocument(file) {
      const fileExist = this.uploadedDocuments.find(
        (f) => f.doc_id === file.doc_id
      );
      if (fileExist) {
        const docs = this.uploadedDocuments.filter(
          (item) => item.doc_id === file.doc_id
        );
        this.uploadedDocuments = docs;
      } else {
        this.uploadedDocuments.push(file);
      }
    },
    apiCaller(payload, docs = true) {
      doctorApi
        .updateDoctorInfo(payload)
        .then((response) => {
          core.showSnackbar("success", response.data.message);
        })
        .then(() => {
          if (docs) {
            doctorApi
              .updateDoctorDocuments({ documents: this.uploadedDocuments })
              .then((response) => {
                core.showSnackbar("success", response.data.message);
              });
          }
        })
        .finally(() => {
          this.loadingButtonSubmit = false;
        });
    },
    onSubmit() {
      this.loadingButtonSubmit = true;

      // Handle doctor services
      const doctorServices = {
        allow_clinic_examine: false,
        allow_outdoor_examine: false,
        allow_consulting: false,
      };

      this.doctorServiceSelected.forEach((item) => {
        const key = Object.keys(item)[0];
        doctorServices[key] = true;
        console.log("doctorServiceSelected is .....", key);
      });

      // Handle work times
      const workOfTimes = this.work_times.map((item, index) => {
        return {
          ...item,
          max_appointments:
            this.selectedDetectionMethod === "first-come-first-served"
              ? this.max_examinations_count[index]
              : null,
          working_periods: item.is_active ? item.working_periods : [],
        };
      });

      // Create the payload
      const payload = {
        full_name: this.userData.full_name,
        email: this.userData.email,
        primary_phone_number: this.userData.primary_phone_number,
        secondary_phone_number: this.userData.secondary_phone_number,
        clinic_address: this.userData.clinic_address,
        work_times: workOfTimes,
        IBAN: this.ibanInput, // Copy from ibanInput
        examine_method: this.selectedDetectionMethod,
        ...doctorServices,
      };

      // Conditionally include examination_duration
      if (this.selectedDetectionMethod !== "first-come-first-served") {
        payload.examination_duration = `${this.examination_duration.hours}:${this.examination_duration.minutes}:${this.examination_duration.seconds}`;
      }

      const selectedPrices = {};

      if (doctorServices.allow_clinic_examine) {
        selectedPrices.clinic_examine = this.prices.clinic_examine;
      }

      if (doctorServices.allow_outdoor_examine) {
        selectedPrices.outdoor_examine = this.prices.outdoor_examine;
      }

      if (doctorServices.allow_consulting) {
        selectedPrices.consulting = this.prices.consulting;
      }

      payload.prices = selectedPrices;
      console.log("payload prices is :.......", payload.prices);
      console.log("payload is :.............", payload);

      if (this.userData.status !== "accepted") {
        console.log("this.userData.status", this.userData.status);
        if (
          (this.requiredDocuments &&
            this.requiredDocuments.length === this.uploadedDocuments.length) ||
          this.userData.uploaded_documents.length
        ) {
          this.apiCaller({ ...payload, status: "processing" });
        } else {
          core.showSnackbar("error", "please upload your all documents");
          this.loadingButtonSubmit = false;
        }
      } else {
        this.apiCaller(payload, false);
      }
    },
  },
  watch: {
    userData(val) {
      if (val) {
        this.doctorServiceSelected = [
          { allow_clinic_examine: Boolean(val.allow_clinic_examine) },
          { allow_outdoor_examine: Boolean(val.allow_outdoor_examine) },
          { allow_consulting: Boolean(val.allow_consulting) },
        ];
        // examination method
        this.selectedDetectionMethod = val.examine_method;
        // examination duration
        const splitDuration = val.examination_duration.split(":");
        this.examination_duration = {
          hours: splitDuration[0],
          minutes: splitDuration[1],
          seconds: "00",
        };

        // work times
        if (val.work_times) {
          const newItems = val.work_times.map((item) => {
            if (!item.is_active) {
              if (item.working_periods.length) {
                return item;
              } else {
                return {
                  ...item,
                  working_periods: [{ to: "", from: "" }],
                };
              }
            } else {
              return item;
            }
          });
          this.work_times = newItems;
        } else {
          this.work_times = [
            {
              day: "Saturday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
            {
              day: "Sunday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
            {
              day: "Monday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
            {
              day: "Tuesday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
            {
              day: "Wednesday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
            {
              day: "Thursday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
            {
              day: "Friday",
              is_active: false,
              working_periods: [{ to: "", from: "" }],
            },
          ];
        }

        // prices
        this.prices = {
          clinic_examine:
            val.prices && val.allow_clinic_examine
              ? val.prices.clinic_examine
              : "",
          consulting:
            val.prices && val.allow_consulting ? val.prices.consulting : "",
          outdoor_examine:
            val.prices && val.allow_outdoor_examine
              ? val.prices.outdoor_examine
              : "",
        };
      } else {
        this.doctorServiceSelected = [
          { allow_clinic_examine: false },
          { allow_outdoor_examine: false },
          { allow_consulting: false },
        ];
      }
    },
    work_times(newVal, oldVal) {
      // Keep max_examinations_count synced with work_times
      if (newVal.length !== oldVal.length) {
        this.max_examinations_count = newVal.map(
          (item) => item.max_appointments || ""
        );
      } else {
        // Update existing values if work_times changes
        newVal.forEach((item, index) => {
          if (item.max_appointments !== this.max_examinations_count[index]) {
            this.max_examinations_count[index] = item.max_appointments || "";
          }
        });
      }
    },
  },
  created() {
    this.getUserInfo();
    this.getRequiredDocuments();
  },
};
</script>
